import React from 'react'

import Layout from 'components/layout'

const NotFoundPage = () => {
  return (
    <Layout>
      <h1>Nothing was found</h1>
    </Layout>
  )
}

export default NotFoundPage
